<template>
  <div class="card">
    <h3 class="card-title">Visualizar área</h3>
    <template v-if="area">
      <h4 class="">
        <span class="text-secondary">Descripción:</span>
        {{ area.descripcion }}
      </h4>
      <h4 class="">
        <span class="text-secondary">Gerencia:</span>
        {{ area.gerencia.descripcion }}
      </h4>
      <h4 class="">
        <span class="text-secondary">Jefe:</span>
        {{ area.jefe.nombre }}
      </h4>
      <div class="d-flex space-between">
        <button class="btn btn-info" @click="$router.push({name:'ListarAreas'})">Volver</button>
        <div>
          <button class="btn btn-warning" @click="openEdit = true">Editar área</button>
          <button class="btn btn-error" @click="eliminarArea">
            Eliminar area
          </button>
        </div>
      </div>
      <modal v-if="openEdit" @close="openEdit = false">
        <editar-area slot="modal-body" :area="area" @saved="saved"></editar-area>
      </modal>
    </template>
  </div>
</template>
<script>
import { AreaServices } from "../services/AreaServices";
import Swal from "sweetalert2";
import Modal from "@/components/elements/Modal";
import EditarArea from "./EditarArea.vue";
export default {
  name: "VerArea",
  components:{
    Modal, EditarArea
  },
  data() {
    return {
      area: null,
      openEdit: false
    };
  },
  mounted() {
    AreaServices.api.find(this.$route.params.id).then((response) => {
      this.area = response.area;
    });
  },
  methods: {
    eliminarArea() {
      Swal.fire({
        title: "Confirme",
        html: `<p>Está seguro que desea eliminar <b>${this.area.descripcion}</b>?</p>`,
        icon: "question",
        showConfirmButton: true,
        confirmButtonText: "Eliminar",
        showCancelButton: true,
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          AreaServices.api.delete(this.area).then(() => {
            Swal.fire("Éxito", "<p>Se ha eliminado con éxito</p>", "success");
            this.$router.push({ name: "ListarAreas" });
          });
        }
      });
    },
    saved(){
      this.openEdit = false
      AreaServices.api.find(this.area.id).then((response) => {
      this.area = response.area;
    });
    }
  },
};
</script>