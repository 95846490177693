<template>
  <div>
    <h3 class="card-title">Editar área</h3>
    <form @submit.prevent="save" style="min-width: 500px">
      <pd-input
        v-model="descripcion"
        id="descripcion"
        label="Nombre"
        placeholder="Ingrese el nombre de la gerencia"
        class="my-1"
      ></pd-input>
      <suggest
        id="jefe"
        label="Jefe"
        placeholder="Buscar jefe.."
        class="my-1"
        :data="personas"
        :items="itemsPersona"
        :value="area.jefe.nombre"
        @selected="jefeSelected"
        :required="enviado && !jefe"
      ></suggest>
      <suggest
        id="gerencia"
        label="Gerencias"
        placeholder="Buscar gerencia.."
        class="my-1"
        :data="gerencias"
        :items="itemsGerencia"
        :value="area.gerencia.descripcion"
        @selected="gerenciaSelected"
        :required="enviado && !gerencia"
      ></suggest>
      <button class="btn btn-right btn-primary">Guardar</button>
    </form>
  </div>
</template>
<script>
import Suggest from "@/components/elements/Suggest";
import { PersonasService } from "@/modules/personas/services/PersonasServices";
import { GerenciaServices } from "@/modules/gerencias/services/GerenciaServices";
import { AreaServices } from "../services/AreaServices";
import Swal from "sweetalert2";
export default {
  name: "EditarArea",
  data() {
    return {
      personas: null,
      descripcion: "",
      jefe: null,
      gerencia:null,
      gerencias:null,
      itemsGerencia: GerenciaServices.dataList(),
      itemsPersona: PersonasService.dataList(),
      enviado: false
    };
  },
  props: {
    area: null,
  },
  components: {
    Suggest,
  },
  mounted() {
    this.descripcion = ""+{...this.area}.descripcion;
    PersonasService.api.getAllForSelect().then((personas) => {
      this.personas = personas;
      this.jefe ={...this.area.jefe}
      this.gerencia ={...this.area.gerencia}
    });
  },
  methods: {
    jefeSelected(jefe) {
      this.jefe = jefe;
    },
    gerenciaSelected(gerencia) {
      this.gerencia = gerencia;
    },
    save() {
      AreaServices.api.put(this.area.id,{
        descripcion: this.descripcion,
        jefe_id: this.jefe.id,
        gerencia_id: this.gerencia.id,
      })
        .then((r) => {
          Swal.fire(
            "Éxito",
            `<p><b>${r.area.descripcion}</b> se ha guardado con éxito</p>`,
            "success"
          );
          this.$emit("saved");
        })
        .catch((e) => {
          Swal.fire("Error", `<p>${e}</p>`, "error");
        });
    },
  },
};
</script>