import Axios from 'axios'
import { required, numeric } from "vuelidate/lib/validators";

export const GerenciaServices = {
    //API
    api: {
        async find(id) {
            return Axios.get('gerencias/' + id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => { throw error })
        },
        async getAll() {
            return Axios.get('gerencias')
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async save(gerencia) {
            return Axios.post('gerencias', gerencia)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async put(id, gerencia) {
            return Axios.put('gerencias/' + id, gerencia)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
        async delete(gerencia) {
            return Axios.delete('gerencias/' + gerencia.id)
                .then((response) => {
                    return response.data
                })
                .catch((error) => {
                    throw error
                })
        },
    },
    //VALIDATIONS
    validations: {
        descripcion: { required },
        gerencia_id: { required, numeric },
    },
    //TABLELIST
    headers() {
        const header = [{ value: '#', sortable: true }, { value: 'Gerencia', sortable: true }, { value: 'Gerente', sortable: true }]
        return header;
    },
    body() {
        const body = [{
                class: 'w-100',
                data: [{ value: 'id', class: 'text-primary', label: '#' }]
            },
            {
                class: 'important',
                data: [{ value: 'descripcion' }]
            },
            {
                class: 'w-100',
                data: [{ value: 'gerente.nombre', label: 'Gerente' }]
            }
        ]
        return body
    },
    dataList() {
        return [
            { name: "descripcion", class: "" },
            { name: "gerente.nombre", class: "text-secondary" },
        ]
    }

}